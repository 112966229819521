import React, { useState, useEffect } from 'react';
import '../css/admin-page.css';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLogs, deleteLog } from '../redux/actions';

function AdminPage() {
    const dispatch = useDispatch();
    const logs = useSelector(state => state.logs.logs);
    const [sortConfig, setSortConfig] = useState({ key: 'timestamp', direction: 'descending' });

    useEffect(() => { 
        dispatch(fetchLogs()); 
    }, [dispatch]);

    useEffect(() => {
        console.log("Logs from server: ", logs);
    });

    const sortedLogs = [...logs].sort((a, b) => { 
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });

    const requestSort = key => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const deleteItem = (log) => {
        dispatch(deleteLog(log));  
    }
      

    const refresh = () => {
        dispatch(fetchLogs());
    };

    function formatDateSerbian(dateString) {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.getMonth() + 1; 
        const year = date.getFullYear();
        const hours = date.getHours();
        const minutes = date.getMinutes();
    
        return `u ${hours}h:${minutes.toString().padStart(2, '0')}m, dana ${day}.${month}.${year}`;
    }

    return (
        <div className="admin-page-container">
            <h1>Orto Print Signal Generator Logs</h1>
            <div className="table-wrapper">
            <table>
                <thead>
                    <tr>
                        <th onClick={() => requestSort('device_id')}>
                            ID {sortConfig.key === 'device_id' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                        </th>
                        <th onClick={() => requestSort('event_type')}>
                            Stanje {sortConfig.key === 'event_type' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                        </th>
                        <th onClick={() => requestSort('timestamp')}>
                            Datum {sortConfig.key === 'timestamp' ? (sortConfig.direction === 'ascending' ? '↑' : '↓') : ''}
                        </th>
                        <th>Radnja</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedLogs.map(log => (
                        <tr key={log.timestamp}>
                            <td>{'Uredjaj: ' + log.device_id}</td>
                            <td>{log.event_type === 'ON' ? 'Ukljucen' : 'Iskljucen' }</td>
                            <td>{formatDateSerbian(log.timestamp)}</td>
                            <td>
                                <button onClick={() => deleteItem(log)}>Ukloni</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
            <button className="delete-all-btn" onClick={refresh}>
                Osvezi
            </button>
        </div>
    );
}

export default AdminPage;
